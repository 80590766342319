<template>
  <div class="page-view home">
    <div v-if="!loading && !error">
      <div class="container section-container intro-container">
        <!-- INTRO -->
        <section class="intro-home flex-wrapper">
          <figure class="hidden lg:block">
            <img src="@/assets/img/cashback/clouds.png" />
          </figure>
          <h3>
            Benvenuto nel <i>Cashback di Genertellife</i>: completa i tuoi dati
            per ottenere il rimborso
            <br /><router-link to="/come-funziona">Scopri l'iniziativa</router-link>
          </h3>
          <figure class="hidden lg:block text-right">
            <img src="@/assets/img/cashback/clouds_2.png" />
          </figure>
        </section>
      </div>
      <hr />

      <!-- USER DATA -->
      <div class="overflow-hidden relative z-10">
        <div class="container section-container sm:-mb-14">
          <h4 class="section-title text-primary-black mb-4 text-lg font-bold"
            v-if="user.updated1of2024 && user.cashback1of2024">
            <span class="text-xs inline-block align-middle">•</span> Il tuo rimborso per polizze dal 01/02/2024 al
            07/04/2024 è di € {{ user.cashback1of2024 ? user.cashback1of2024 : '-' }}
            <time class="block ml-2">Aggiornato al {{ formattedData(user.updated1of2024) }}</time>
          </h4>
          <!-- <h4 class="section-title text-primary-black mb-4 text-lg font-bold" v-if="user.updated5 && user.cashback5">
            <span class="text-xs inline-block align-middle">•</span> Il tuo rimborso per polizze dal 08/06/2023 al 31/07/2023 è di € {{ user.cashback5 ? user.cashback5 : '-' }}
            <time class="block ml-2">Aggiornato al {{ formattedData(user.updated5) }}</time>
          </h4>
          <h4 class="section-title text-primary-black text-lg font-bold" v-if="user.updated4 && user.cashback4">
            <span class="text-xs inline-block align-middle">•</span> Il tuo rimborso per polizze dal 01/03/2023 al 31/05/2023 è di € {{user.cashback4 ? user.cashback4 : '-'}}
            <time class="block ml-2">Aggiornato al {{formattedData(user.updated4) }}</time>
          </h4> -->
        </div>
        <div class="container section-container flex-container">
          <h4 class="section-title side">
            I tuoi<br /> Dati:
          </h4>
          <div class="form-wrapper">
            <Form>
              <!-- <div class="input-wrapper no-bg highlight">
                <label for="rimborso">Il tuo rimborso è di €</label>
                <span class="input" id="rimborso">{{user.cashback ? user.cashback : '-'}}</span>
              </div>
              <time v-if="user.updated" class="mb-2">Aggiornato al {{formattedData(user.updated)}}</time> -->
              <div class="input-wrapper no-bg">
                <label for="surnameNotEdit">Cognome</label>
                <span class="input" id="surnameNotEdit">{{user.surname ? user.surname : '-'}}</span>
              </div>
              <div class="input-wrapper no-bg">
                <label for="nameNotEdit">Nome</label>
                <span class="input" id="nameNotEdit">{{user.name ? user.name : '-'}}</span>
              </div>
              <div class="input-wrapper no-bg">
                <label for="codfisc">Codice fiscale</label>
                <span class="input" id="codfisc">{{user.fiscal}}</span>
              </div>
            </Form>
          </div>
        </div>
        <figure class="main-img">
          <img src="@/assets/img/cashback/1000x700_v2.png" />
        </figure>
      </div>

      <!-- COMPLETE DATA -->
      <div class="bg-primary-xlgray" v-if="!done && !user.iban">
        <div class="container section-container flex-container">
          <h4 class="section-title side">
            Dati <br />per il <br />bonifico <br />bancario:<br class="mobile" /><br class="mobile" />
            <small>*Campo obbligatorio</small>
            <small>**Campo facoltativo</small>
          </h4>
          <div class="form-wrapper half-wrapper">
            <small class="italic text-sm mb-2 pl-2 w-full inline-block opacity-75">
              - Il conto corrente bancario deve essere intestato a: {{user.surname + ' ' + user.name}}.
            </small>
            <Form @submit="tryUpdate">
              <!-- <div class="input-wrapper">
                <label for="surname">Cognome</label>
                <span class="input" id="surname">{{user.surname ? user.surname : '-'}}</span>
              </div>
              <div class="input-wrapper">
                <label for="name">Nome</label>
                <span class="input" id="name">{{user.name ? user.name : '-'}}</span>
              </div> -->
              <div class="input-wrapper">
                <label for="iban">Codice IBAN*</label>
                <Field class="editable" name="iban" rules="req" placeholder="" />
                <ErrorMessage name="iban" />
              </div>
              <div class="input-wrapper">
                <label for="confIban">Conferma codice IBAN*</label>
                <Field class="editable" name="confIban" rules="req|confirmed:@iban" placeholder="" />
                <ErrorMessage name="confIban" />
              </div>
              <div class="input-wrapper">
                <label for="banca">Agenzia Banca di Riferimento**</label>
                <Field class="editable" name="banca" placeholder="" />
                <ErrorMessage name="banca" />
              </div>
              <div class="clearfix"></div>
              <div class="closing-section">
                <hr />
                <div class="field-wrapper">
                  <Field name="terms" type="checkbox" value="true" />
                  <label for="terms" class="for-checkbox">
                    Accetto <a href="/termini_condizioni_2024_1.pdf" target="_blank">Termini & Condizioni</a>
                    dell'iniziativa.
                  </label>
                  <ErrorMessage name="terms" />
                </div>
                <!-- <div class="field-wrapper">
                  <Field name="info" rules="req" type="checkbox" value="true" />
                  <label for="info" class="for-checkbox">
                    Dichiaro di aver letto e compreso l'<a href="#" target="_blank">Informativa</a> relativa al trattamento dei dati personali da me
                    forniti messa a disposizione e di prestare a Genertellife S.p.A. in qualità di titolare del trattamento,
                    il consenso (che potrò in ogni caso successivamente revocare) all'utilizzo dei miei dati personali per
                    le finalità connesse alla partecipazione all’iniziativa.*
                  </label>
                  <ErrorMessage name="info" />
                </div> -->
                <div class="submit-wrapper">
                  <button type="submit" class="btn primary-btn submit">
                    Invia
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>

      <!-- RECAP -->
      <div v-if="user.iban && !done" class="border-t border-b border-primary-lgray">
        <div class="container py-10 mx-auto text-center">
          <h4 class="section-title inline-block">
            Gentile <b>{{user.surname + ' ' + user.name}}</b><br />
            i tuoi dati sono stati registrati: questo il codice IBAN che hai inserito.<br />
            <b class="py-2 px-3 bg-primary-lgray text-primary-black mt-4 mx-2 inline-block">{{user.iban}}</b>
            <span v-if="user.filiale" class="block mt-2 italic text-primary-black text-sm">Filiale:
              {{user.filiale}}</span>
          </h4>
          <figure class="block">
            <img class="inline-block w-134" src="@/assets/img/cashback/result.png" />
          </figure>
          <p class="sm:w-1/2 mx-auto mt-10 px-2">
            Riceverai il rimborso sul tuo conto corrente associato all’IBAN comunicato entro <u>90 giorni</u> dal rispettivo termine dell’iniziativa (30/06/2024). Ti ringraziamo per aver partecipato al <b>Cashback di Genertellife</b>!
          </p>
        </div>
      </div>

      <!-- THANK YOU -->
      <div v-if="done" class="border-t border-b border-primary-lgray">
        <div class="container py-10 mx-auto text-center">
          <h4 class="section-title inline-block">
            Gentile <b>{{user.surname + ' ' + user.name}}</b><br />
            i tuoi dati sono stati registrati.
          </h4>
          <figure class="block">
            <img class="inline-block w-134" src="@/assets/img/cashback/result.png" />
          </figure>
          <p class="sm:w-1/2 mx-auto mt-10 px-2">
            Riceverai il rimborso sul tuo conto corrente associato all’IBAN comunicato entro <u>90 giorni</u> dal rispettivo termine dell’iniziativa (30/06/2024). Ti ringraziamo per aver partecipato al <b>Cashback di Genertellife</b>!
          </p>
        </div>
      </div>
    </div>
    <!-- LOADING -->
    <div v-else-if="loading">
      <div class="loading-section">
        <span class="icon-wrapper">
          <v-icon name="ri-loader-fill" class="animate-spin mr-1" />Caricamento in corso
        </span>
      </div>
    </div>
    <!-- ERROR -->
    <div v-else-if="error">
      <div class="error-section">
        <span class="icon-wrapper">
          <v-icon name="md-reportgmailerrorred-round" class="mr-1" />Pagina non disponibile
        </span>
        <h2>Prosegui sul sito <a href="https://www.genertellife.it/">www.genertellife.it</a></h2>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import moment from 'moment'
moment.locale('it')

export default {
  name: 'Home',
  components: {
    Field,
    Form,
    ErrorMessage
  },
  data () {
    return {
      error: false,
      loading: true,
      done: false,
      interval: null,
      token: this.$route.query.token,
      user: {
        mail: null,
        username: null,
        surname: null,
        name: null,
        fiscal: null,
        cashback: null,
        updated: null,
        cashback2: null,
        updated2: null,
        cashback3: null,
        updated3: null,
        cashback4: null,
        updated4: null,
        cashback5: null,
        updated5: null,
        cashback1of2024: null,
        updated1of2024: null,
        parts: null,
        iban: null,
        filiale: null
      }
    }
  },
  methods: {
    fixCashData (number) {
      let strng = number.toString()
      if (strng.includes('.')) {
        const lngth = strng.split('.')[1].length
        if (lngth === 0) {
          strng = strng + '00'
        } else if (lngth === 1) {
          strng = strng + '0'
        }
      } else {
        strng = strng + '.00'
      }
      console.log('AFTER', strng)
      return parseFloat(strng).toFixed(2)
    },
    formattedData (data) {
      return moment(data).format('DD/MM/YYYY')
    },
    async tryUpdate (values) {
      await this.$store.dispatch('updateUser', {
        username: this.user.username,
        extra_data: {
          iban: values.iban,
          filiale: values.banca
        }
      })
        .then((rsp) => {
          if (rsp.msg_translated) {
            const error = true
            const msg = rsp.msg_translated
            console.error('MSG', msg)
            this.emitter.emit('fireAlert', { msg, error })
          } else {
            this.done = true
            const error = false
            const msg = 'INVIO avvenuto con successo.'
            console.log('Update', rsp)
            this.emitter.emit('fireAlert', { msg, error })
          }
        })
    },
    async tryLogin () {
      const data = {
        access_token: this.token,
        token_type: 'bearer'
      }
      await this.$store.commit('setUser', data)
    },
    checkEnd () {
      const today = moment()
      const end = moment(process.env.VUE_APP_END_DATE)
      if (today.isAfter(end)) {
        return true
      }
      return false
    }
  },
  async mounted () {
    console.log('---STORE', this.$store.state)
    if (this.token && !this.checkEnd()) {
      await this.tryLogin()
      if (this.$store.state.user) {
        await this.$store.dispatch('getUserInfo')
          .then((rsp) => {
            if (rsp.msg_translated) {
              this.error = true
              const error = this.error
              const msg = rsp.msg_translated
              console.error('MSG', msg)
              this.emitter.emit('fireAlert', { msg, error })
              this.$router.push('/come-funziona')
            } else {
              this.error = false
              // const err = this.error
              // const msg = 'LOGIN avvenuto con successo.'
              console.log('Login', rsp)
              // this.emitter.emit('fireAlert', { msg, err })
              this.user.surname = rsp.last_name
              this.user.name = rsp.first_name
              this.user.fiscal = rsp.fiscal_code
              this.user.mail = rsp.email
              this.user.username = rsp.username
              if (rsp.extra_data && rsp.extra_data.iban) this.user.iban = rsp.extra_data.iban
              if (rsp.extra_data && rsp.extra_data.filiale) this.user.filiale = rsp.extra_data.filiale
            }
          })

        let msg = null
        this.user.parts = await this.$store.dispatch('getPartecipations')
          .then((rsp) => {
            if (!rsp.msg_translated) {
              console.log('PARTECIPATIONS', rsp)
              this.error = false
              return rsp
            } else {
              this.error = true
              const error = true
              msg = 'ERRORE durante il recupero del saldo: ricaricare la pagina.'
              this.emitter.emit('fireAlert', { msg, error })
              return null
            }
          })
          .catch((e) => {
            console.error('GET PARTECIPATIONS', msg)
            this.error = true
          })

        if (this.user.parts && this.user.parts[0]) {
          // --- PERIODO 1
          const temp = this.user.parts.filter(function (item) {
            return item.item_kind === process.env.VUE_APP_CAMPAIGN
          })

          if (temp[0]) {
            this.user.cashback = this.fixCashData(temp[0].item_number)
            this.user.updated = temp[0].item_date
          }

          // --- PERIODO 2
          const temp2 = this.user.parts.filter(function (item) {
            return item.item_kind === process.env.VUE_APP_CAMPAIGN_2
          })

          if (temp2[0]) {
            this.user.cashback2 = this.fixCashData(temp2[0].item_number)
            this.user.updated2 = temp2[0].item_date
          }

          // --- PERIODO 3
          const temp3 = this.user.parts.filter(function (item) {
            return item.item_kind === process.env.VUE_APP_CAMPAIGN_3
          })

          if (temp3[0]) {
            this.user.cashback3 = this.fixCashData(temp3[0].item_number)
            this.user.updated3 = temp3[0].item_date
          }

          // --- PERIODO 4
          const temp4 = this.user.parts.filter(function (item) {
            return item.item_kind === process.env.VUE_APP_CAMPAIGN_4
          })

          if (temp4[0]) {
            this.user.cashback4 = this.fixCashData(temp4[0].item_number)
            this.user.updated4 = temp4[0].item_date
          }

          // --- PERIODO 5
          const temp5 = this.user.parts.filter(function (item) {
            return item.item_kind === process.env.VUE_APP_CAMPAIGN_5
          })

          if (temp5[0]) {
            this.user.cashback5 = this.fixCashData(temp5[0].item_number)
            this.user.updated5 = temp5[0].item_date
          }

          // --- PERIODO 1 2024
          const temp1of2024 = this.user.parts.filter(function (item) {
            return item.item_kind === process.env.VUE_APP_CAMPAIGN_2024
          })

          if (temp1of2024[0]) {
            this.user.cashback1of2024 = this.fixCashData(temp1of2024[0].item_number)
            this.user.updated1of2024 = temp1of2024[0].item_date
          }
        }
      } else {
        this.error = true
        this.$router.push('/come-funziona')
      }
    } else {
      console.warn('---CONTEST END')
      this.error = true
      this.$router.push('/come-funziona')
    }
    setTimeout(() => {
      this.loading = false
    }, 1000)
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/scss/forms.scss";
  @import "@/assets/scss/tables.scss";
  @import "@/assets/scss/views/home.scss";
</style>
